
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import IndividualClientsTable from "@/views/admin/users/individual-clients/IndividualClientsTable.vue";
import variables from "@/router/api";

export default defineComponent({
  name: "admin-add-new-administrator",
  components: {
    IndividualClientsTable,
  },
  data() {
    return {
      route: `${variables.SUPERADMIN_USERS_ROUTE}/individual-client-user`,
    };
  },
  setup() {
    setCurrentPageBreadcrumbs("Individual Clients", ["Administrator", "Users"]);
  },
  created() {
    //Set page title
    document.title = "Individual Clients | " + process.env.VUE_APP_TITLE;
  },
});
